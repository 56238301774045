import { identity } from 'fp-ts/lib/function';
import { getLastSemigroup } from 'fp-ts/lib/Semigroup';
import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import * as A from 'fp-ts/lib/Array';
import * as O from 'fp-ts/lib/Option';
import * as TE from 'fp-ts/lib/TaskEither';
import * as R from 'fp-ts/lib/Record';
import { FunctionN } from 'fp-ts/lib/function';
import * as S from 'fp-ts/lib/Semigroup';
import * as NEA from 'fp-ts/lib/NonEmptyArray';

type Lit = string | number | boolean | undefined | null | void | {};

export type Compact<T extends Record<string | number | symbol, unknown>> = {
  [K in keyof T]: T[K] extends O.Option<infer U> ? U : T[K];
};

export function tuple<T extends Lit[]>(...args: T): T {
  return args;
}

export function keys<O extends {}>(o: O) {
  return Object.keys(o) as (keyof O)[];
}

export function toArray<K extends string, A>(r: Record<K, A>): [K, A][] {
  return Object.entries(r) as [K, A][];
}

export function zipObject<K extends string, A>(
  keys: Array<K>,
  values: Array<A>,
): Record<K, A> {
  return R.fromFoldableMap(getLastSemigroup<A>(), A.array)(
    A.zip(keys, values),
    identity,
  );
}

export function fromPairs<K extends string, A>(
  xs: Array<[K, A]>,
): Record<K, A> {
  return R.fromFoldable(getLastSemigroup<A>(), A.array)(xs);
}

export function fromArrayMap<K1 extends string, K2 extends string, A>(
  keys: Array<K1>,
  fn: (key: K1) => [K2, A],
): Record<K2, A> {
  return R.fromFoldableMap(getLastSemigroup<A>(), A.array)(keys, fn);
}

export function collectWhile<I, E, A>(
  iter: (i: I) => TE.TaskEither<E, [A, O.Option<I>]>,
): (i: I) => TE.TaskEither<E, NEA.NonEmptyArray<A>> {
  const go =
    (bs: Array<A>) =>
    (i: I): TE.TaskEither<E, NEA.NonEmptyArray<A>> =>
      pipe(
        iter(i),
        TE.chain(a => {
          const [b, oi] = a;
          const nebs = A.snoc(bs, b);
          return pipe(
            oi,
            O.fold(() => TE.right(nebs), go(nebs)),
          );
        }),
      );
  return go(A.empty);
}

export function semigroupFoldGroups<A>(
  p: FunctionN<[A], string>,
  m: S.Semigroup<A>,
): (as: Array<A>) => Array<A> {
  return as =>
    pipe(
      as,
      NEA.groupBy(p),
      R.map(x => S.fold(m)(NEA.head(x), NEA.tail(x))),
      Object.values,
    );
}

export function maybe<T extends t.Mixed>(x: T) {
  return t.union([t.null, t.undefined, x]);
}

export const sum: (xs: number[]) => number = A.reduce(
  0,
  (a, b: number) => a + b,
);

export const div = (a: number) => (b: number) => b / a;
