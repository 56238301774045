import React from 'react';
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export function useClientSideBoundary(options: { filter?: () => boolean }) {
  const [clientSide, setClientSide] = React.useState<boolean>(false);
  const ssr = typeof window === 'undefined';

  React.useEffect(() => {
    if (ssr) {
      return;
    }

    setClientSide(
      pipe(
        O.fromNullable(options.filter),
        O.fold(
          () => true,
          filter => filter(),
        ),
      ),
    );
  }, []);

  return clientSide;
}

export function ClientSideBoundary(props: Props) {
  const isClientSide = useClientSideBoundary({});

  if (!isClientSide) {
    return null;
  }

  return <React.Fragment>{props.children}</React.Fragment>;
}
