import React from 'react';
import { pipe } from 'fp-ts/lib/function';
import { map, mapWithIndex } from 'fp-ts/lib/Record';
import { keys } from './fp';

type MediaQueryFn = () => MediaQueryList;
type MediaQueries<K extends string> = Record<K, () => MediaQueryList>;
type MediaT<K extends string> = Record<K, boolean>;

function reducer<K extends string>(
  state: MediaT<K>,
  action: { type: K; payload: boolean },
): MediaT<K> {
  return { ...state, [action.type]: action.payload };
}

export function createMediaContext<K extends string>(media: MediaQueries<K>) {
  const empty = pipe(
    media,
    map(() => true),
  );

  const Context = React.createContext<MediaT<K>>(empty);
  Context.displayName = 'MediaContext';

  function MediaProvider(props: { children: JSX.Element }) {
    const ssr = typeof window === 'undefined';
    const [state, dispatch] = React.useReducer(reducer, empty);

    React.useEffect(() => {
      if (ssr) {
        return;
      }

      pipe(
        media,
        mapWithIndex((k, v: MediaQueryFn) =>
          dispatch({ type: k, payload: v().matches }),
        ),
      );
    }, []);

    React.useEffect(() => {
      const xs = keys(media).map((k: K) => {
        const mql = media[k]();

        function listener(ev: { matches: boolean }) {
          dispatch({ type: k, payload: ev.matches });
        }

        listener.bind(mql);
        mql.addListener(listener);

        return { mql, listener };
      });

      return () => {
        xs.forEach(x => {
          x.mql.removeListener(x.listener);
        });
      };

      // reusing the same dispatch function seems to work better than
      // unmounting and remounting the listener
    }, []);

    return React.createElement(
      Context.Provider,
      { value: state },
      props.children,
    );
  }

  return { Provider: MediaProvider, Consumer: Context.Consumer, Context };
}

export const mediaStops = {
  xs: '(min-width: 0px)',
  sm: '(min-width: 576px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 992px)',
  xl: '(min-width: 1200px)',
};

const BootstrapContext = createMediaContext({
  xs: () => window.matchMedia('(min-width: 0px)'),
  sm: () => window.matchMedia('(min-width: 576px)'),
  md: () => window.matchMedia('(min-width: 768px)'),
  lg: () => window.matchMedia('(min-width: 992px)'),
  xl: () => window.matchMedia('(min-width: 1200px)'),
  print: () => window.matchMedia('print'),
});

// Provider that manages media state
export const Provider = BootstrapContext.Provider;

// Render-props based API
export const Media = BootstrapContext.Consumer;

export const MediaContext = BootstrapContext.Context;

// React Hook
export const useMedia = () => React.useContext(BootstrapContext.Context);

export const mediaDecorator = (s: () => React.ReactNode) =>
  React.createElement(Provider, null, s());
