import React, { AnchorHTMLAttributes } from 'react';
import { navigate } from 'gatsby';

type LinkProps = {
  to: string;
  activeClassName?: string;
  partiallyActive?: boolean;
  className?: string;
  children?: React.ReactNode | React.ReactNodeArray;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

const DefaultLink = ({ to, children, ...props }: LinkProps) => (
  <a href={to} {...props}>
    {children}
  </a>
);

const LinkContext =
  React.createContext<React.ComponentType<LinkProps>>(DefaultLink);

export const LinkProvider = LinkContext.Provider;

export const Link = (props: LinkProps) => {
  const c = props.target ? DefaultLink : React.useContext(LinkContext);
  return React.createElement(c, props);
};

type RedirectProps = {
  to: string;
};

export function Redirect(props: RedirectProps) {
  React.useEffect(() => {
    navigate(props.to);
  }, []);
  return <React.Fragment />;
}
