import React from 'react';
import queryString from 'query-string';
import { useClientSideBoundary } from '@gromia/components/ClientSideBoundary';
import IntlMessageFormat from 'intl-messageformat';
import { replaceAll } from '@gromia/utils/string';
import { pipe } from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';
import * as A from 'fp-ts/lib/Array';
import * as t from 'io-ts';
import * as s from './useString.module.css';

function printStringCreator(page: string, key: unknown, fileName: string) {
  // const it_IT = `${page}.${key}`;
  const it_IT = `Lorem ipsum`;
  const message = `
echo '{
  "key": "${page}.${key}",
  "it_IT": "${it_IT}"
}' > _cms/strings/${fileName}.json`.trim();
  setTimeout(console.log.bind(console, message));
}

type R = Record<string, string | null>;

type Options = {
  transform: (s: string) => string;
  variables: Record<string, string | number>;
  key?: string;
};

type TxEl = React.ReactElement<unknown>;

export type TxContext = {
  key: string;
  raw: string;
  element: TxEl;
  withCmsLink: (
    child: NonNullable<React.ReactNode>,
    collection: 'string' | 'markdown',
  ) => React.ReactElement;
};

export function wrapStaticString(value: string): TxContext {
  function withCmsLink(
    child: NonNullable<React.ReactNode>,
    _collection: 'string' | 'markdown',
  ) {
    return <span>{child}</span>;
  }
  return {
    key: '$',
    raw: value,
    element: <span>{value}</span>,
    withCmsLink,
  };
}

export type TxUnsafe = (s: string, options?: Partial<Options>) => TxEl;

type Props = {
  page: string;
  identifier: string;
  collection?: 'string' | 'markdown';
  children: NonNullable<React.ReactNode>;
};

function editOnCmsLink(props: Props) {
  const slug = `${props.page}-${props.identifier}`;

  if (process.env.NODE_ENV === 'development') {
    const host: string = 'http://localhost:1337';
    return `${host}/cms/${slug}`;
  } else {
    const collection = props.collection || 'string';
    const host: string = process.env.CMS_HOST || '';
    return `${host}/cms/#/collections/${collection}/entries/${slug}`;
  }
}

function StringWithCmsLink(props: Props) {
  const showCmsLinks = useCms();

  function editOnCms(e: React.MouseEvent) {
    e.preventDefault();
    window.open(editOnCmsLink(props), '_blank');
  }

  if (!showCmsLinks) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }

  return (
    <span className={s.container}>
      <span onClick={editOnCms} className={s.edit}>
        edit
      </span>
      {props.children}
    </span>
  );
}

const CmsContext = React.createContext<boolean>(false);

export function CmsProvider(props: { children: React.ReactNode }) {
  const active = useClientSideBoundary({
    filter: () => !!queryString.parse(window.location.search).cms,
  });
  return (
    <CmsContext.Provider value={active}>{props.children}</CmsContext.Provider>
  );
}

export function useCms(): boolean {
  return React.useContext(CmsContext);
}

function cleanHtml(src: string) {
  return pipe(
    src,
    replaceAll("'<b>'", '<b>'),
    replaceAll("'</b>'", '</b>'),
    replaceAll('<b>', "'<b>'"),
    replaceAll('</b>', "'</b>'"),
  );
}

export function renderString(
  rawValueString: string,
  options?: Partial<Options> & { onError: () => void },
): string {
  if (options && options.variables) {
    try {
      const msg = new IntlMessageFormat(cleanHtml(rawValueString), 'it-IT');
      const result = msg.format(options.variables);
      if (t.string.is(result)) {
        rawValueString = result;
      } else {
        console.error(
          'unexpected message format result',
          JSON.stringify(result),
        );
      }
    } catch (e) {
      options.onError();
      console.error(e);
    }
  }

  return options && options.transform
    ? options.transform(rawValueString)
    : rawValueString;
}

export function useString<X extends R>(page: string, x: X) {
  function value(k: keyof X, options?: Partial<Options>): string {
    const value: string | null | undefined = x[k];
    const rawValueString: string = value ? value : `${page}-${String(k)}`;

    function onError() {
      console.log(`error in '${String(k)}'`);
    }

    if (value === undefined) {
      printStringCreator(page, k, rawValueString);
    }

    return renderString(rawValueString, { ...options, onError });
  }

  function keyFor(k: unknown, options?: Partial<Options>) {
    return A.compact([
      O.some(page),
      O.some(k),
      pipe(
        options,
        O.fromNullable,
        O.mapNullable(x => x.key),
      ),
    ]).join('-');
  }

  type TxProps = { k: keyof X; options?: Partial<Options> };

  function Tx(props: TxProps) {
    const key = keyFor(props.k, props.options);
    const valueString = value(props.k, props.options);
    const isDev = process.env.NODE_ENV === 'development';
    const data = isDev ? { 'data-tx-key': key } : {};

    return (
      <StringWithCmsLink key={key} page={page} identifier={props.k.toString()}>
        <span {...data} dangerouslySetInnerHTML={{ __html: valueString }} />
      </StringWithCmsLink>
    );
  }

  function tx(k: keyof X, options?: Partial<Options>): TxEl {
    return <Tx key={keyFor(k, options)} k={k} options={options} />;
  }

  function txDynamic(k: string, options?: Partial<Options>): TxEl {
    return <Tx key={keyFor(k, options)} k={k} options={options} />;
  }

  function raw(k: keyof X, options?: Partial<Options>): TxContext {
    const raw = value(k, options);

    function withCmsLink(
      child: NonNullable<React.ReactNode>,
      collection: 'string' | 'markdown',
    ) {
      return (
        <StringWithCmsLink
          key={`${page}-${String(k)}`}
          page={page}
          identifier={k.toString()}
          collection={collection}
        >
          {child}
        </StringWithCmsLink>
      );
    }

    return {
      key: k as string,
      raw,
      element: tx(k, options),
      withCmsLink,
    };
  }

  return { tx, txDynamic, raw };
}

export type TranslatableStringsBase = R;
export type TranslatableProps<T extends TranslatableStringsBase> = {
  useStringKey: string;
  strings: T;
};
