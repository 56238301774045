import * as React from 'react';
import { LinkProvider } from '@gromia/components/Link';
import { Link } from 'gatsby';
import { Provider as MediaProvider } from '@gromia/utils/useMedia';
import { CmsProvider } from '@gromia/utils/useString';
import qs from 'query-string';
import { getTrackingData, saveTrackingData } from './src/tracking';

import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';

export function wrapRootElement({ element }: { element: any }) {
  return (
    <CmsProvider>
      <LinkProvider value={Link}>
        <MediaProvider>{element}</MediaProvider>
      </LinkProvider>
    </CmsProvider>
  );
}

export function onRouteUpdate() {
  saveTrackingData(getTrackingData());
}

// https://github.com/gatsbyjs/gatsby/issues/2177#issuecomment-400976675
export function onClientEntry() {
  require('intl-pluralrules');
  return new Promise((resolve, reject) => {
    // @ts-ignore
    window.__polyfillio__ = () => {
      resolve(null);
    };

    const features = [];

    if (!('Intl' in window)) {
      features.push(`Intl.~locale.it`);
    }

    if (!('fetch' in window)) {
      features.push('fetch');
    }

    // ... detect other missing features

    if (features.length) {
      const s = document.createElement('script');
      const q = qs.stringify({
        features: features.join(','),
        rum: 1,
        flags: 'always',
        callback: '__polyfillio__',
      });
      s.src = `https://cdn.polyfill.io/v2/polyfill.min.js?${q}`;
      s.async = true;
      s.onerror = reject;
      document.head.appendChild(s);
    } else {
      resolve(null);
    }
  });
}
